<script lang="ts" setup>
import { PropType } from 'vue'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { EmitsEnum } from '~~/src/constants/emits'
import { TextSizes } from '~~/src/constants/textSizes'

const props = defineProps({
	text: { type: String as PropType<TranslationKey>, required: true },
})
const { text } = toRefs(props)
const emit = defineEmits([EmitsEnum.Click])
const onClick = () => emit(EmitsEnum.Click)
</script>

<template>
	<CommonText
		:text="text"
		:text-size="TextSizes.BODY_REGULAR"
		@click.stop="onClick"
		class="cursor-pointer text-sky-600 hover:underline"
	/>
</template>
